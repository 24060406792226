import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./matka.scoped.css";
import { useSelector } from "react-redux";
export default function MatkaPlay() {
  const [type, setType] = React.useState("JODI");
  const location = useLocation();
  const params = useParams();
  const game = params?.id;
  const userData = useSelector((state) => state.account.userData);
  const [payload, setPayload] = React.useState({
    JODI: Array.from({ length: 100 }, (_, i) => i).map((item) => {
      return {
        number: item,
        value: "",
      };
    }),
    HARUF: {
      ANDAR: Array.from({ length: 10 }, (_, i) => i).map((item) => {
        return {
          number: item,
          value: "",
        };
      }),
      BAHAR: Array.from({ length: 10 }, (_, i) => i).map((item) => {
        return {
          number: item,
          value: "",
        };
      }),
    },
  });
  return (
    <div className="gameplay">
      <div className="top-bar-container">
        <ul className="top-bar">
          <li
            onClick={() => {
              setType("JODI");
            }}
            className={type == "JODI" ? "active" : ""}
          >
            Jodi
          </li>

          <li
            className={type == "HARUF" ? "active" : ""}
            onClick={() => {
              setType("HARUF");
            }}
          >
            Haruf
          </li>
        </ul>
      </div>
      <div className="counter-game">
        {game} game Ends in <span>-:-:-</span>
      </div>
      {type == "JODI" ? (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pointer">
                <div>
                  <p className="header">Points Remaining</p>
                  <p className="point-value">
                    {userData?.balance -
                      payload[type]?.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0)}
                  </p>
                </div>
                <div>
                  <p className="header">Points Added</p>
                  <p className="point-value">
                    {payload[type]?.reduce((acc, item) => {
                      if (item.value) {
                        return acc + parseInt(item.value);
                      }
                      return acc;
                    }, 0)}
                  </p>
                </div>
              </div>
              <div className="jodi">
                <div className="main-content">
                  {payload[type]?.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10 ? "0" + item?.number : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: payload[type].map((i) => {
                              if (i.number == item.number) {
                                return {
                                  ...i,
                                  value: e.target.value,
                                };
                              }
                              return i;
                            }),
                          });
                        }}
                        type="number"
                        value={item?.value}
                      />
                    </div>
                  ))}
                </div>
                <div className="footer">
                  <button disabled className="btn btn-primary">
                    Play
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pointer">
                <div>
                  <p className="header">Points Remaining</p>
                  <p className="point-value">
                    {userData?.balance -
                      (payload[type].ANDAR.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0) +
                        payload[type].BAHAR.reduce((acc, item) => {
                          if (item.value) {
                            return acc + parseInt(item.value);
                          }
                          return acc;
                        }, 0))}
                  </p>
                </div>
                <div>
                  <p className="header">Points Added</p>
                  <p className="point-value">
                    {payload[type].ANDAR.reduce((acc, item) => {
                      if (item.value) {
                        return acc + parseInt(item.value);
                      }
                      return acc;
                    }, 0) +
                      payload[type].BAHAR.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0)}
                  </p>
                </div>
              </div>
              <div className="haruf">
                <div className="main-content">
                  <h2>Andar Haruf</h2>
                  {payload[type]?.ANDAR.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10 ? "0" + item?.number : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: {
                              ...payload[type],
                              ANDAR: payload[type].ANDAR.map((i) => {
                                if (i.number == item.number) {
                                  return {
                                    ...i,
                                    value: e.target.value,
                                  };
                                }
                                return i;
                              }),
                            },
                          });
                        }}
                        value={item?.value}
                        type="number"
                      />
                    </div>
                  ))}

                  <h2>Bahar Haruf</h2>
                  {payload[type]?.BAHAR.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10 ? "0" + item?.number : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: {
                              ...payload[type],
                              BAHAR: payload[type].BAHAR.map((i) => {
                                if (i.number == item.number) {
                                  return {
                                    ...i,
                                    value: e.target.value,
                                  };
                                }
                                return i;
                              }),
                            },
                          });
                        }}
                        value={item?.value}
                        type="number"
                      />
                    </div>
                  ))}
                </div>
                <div className="footer">
                  <button disabled className="btn btn-primary">
                    Play
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
