import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCompleteGamesAPI,
} from "../service/AuthService";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getProfit } from "../utils/constants";
import { cricketBookMakerPL } from "../utils/profitLoss";

export default function ViewCompleteGames() {
  const location = useLocation();
  const [data, setData] = React.useState([]);
  const [accountLogId, setAccountLogId] = React.useState(
    location?.state?.item?.accountLogId
  );

  const [loading, setLoading] = React.useState(false);

  const getAccountStatement = async () => {
    setLoading(true);
    const { response } = await getBetsFromAccountLogs(accountLogId);
    let response_ = response;
    response_.betResponseDTOList = response_.betResponseDTOList.map(
      calculateExposure
    );
    setLoading(false);
    setData(response_);
  };
  useEffect(() => {
    getAccountStatement();
  }, [accountLogId]);

  const calculateExposure = (data) => {
    let isBack = data?.lay_rate ? false : true;
    let rate = isBack ? data?.back_rate : data?.lay_rate;
    let amount = data?.amount;
    let runs = isBack ? data?.back_run : data?.lay_run;
    let exposures = [];
    let teams = data?.event_name?.split("v").sort((a, b) => a - b);
    teams.forEach((team) => {
      let exposure = {
        team: team,
        rate: rate,
        amount: amount,
        runs: runs,
        isBack: isBack,
      };
      let type = isBack ? "back" : "lay";
      let profit = cricketBookMakerPL(rate, amount, type).profit;
      let loss = cricketBookMakerPL(rate, amount, type).loss;
      if (team.trim() === data?.runner_name) {
        exposure.exposure = isBack ? profit : `-${loss}`;
      } else {
        exposure.exposure = isBack ? `-${loss}` : profit;
      }
      exposures.push(exposure);
    });
    return { ...data, exposures };
  };

  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-sm-16">
            <div className="gx-py-1 gx-px-2 gx-bg-grey gx-bg-flex gx-justify-content-center gx-text-white">
              <span className="gx-font-weight-semi-bold gx-fs-sm">
                Match Bets
              </span>
            </div>
            <div className="ant-table-wrapper">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-bordered ant-table-ping-right ant-table-scroll-horizontal">
                    <div className="ant-table-container">
                      <div
                        className="ant-table-content"
                        style={{ overflow: "auto hidden" }}
                      >
                        <table
                          style={{
                            width: "auto",
                            minWidth: "100%",
                            tableLayout: "auto",
                          }}
                        >
                          <colgroup />
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Team Name</th>
                              {data?.betResponseDTOList &&
                                data?.betResponseDTOList[0]?.event_name
                                  ?.split("v")
                                  ?.sort((a, b) => a - b)
                                  ?.map((item) => (
                                    <th className="ant-table-cell">{item}</th>
                                  ))}
                              <th className="ant-table-cell">Date</th>
                              <th className="ant-table-cell">Rate</th>
                              <th className="ant-table-cell">Amount</th>
                              <th className="ant-table-cell">Mode</th>
                              <th className="ant-table-cell">P&amp;L</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            <tr
                              aria-hidden="true"
                              className="ant-table-measure-row"
                              style={{ height: 0, fontSize: 0 }}
                            >
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                            </tr>
                            {data.betResponseDTOList
                              ?.filter((item) => item?.gameType === "MATCH")
                              .map((match, index) => (
                                <tr
                                  data-row-key={0}
                                  className="ant-table-row ant-table-row-level-0"
                                >
                                  {data?.betResponseDTOList[0]?.event_name
                                    ?.split("v")
                                    ?.map((item) => (
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap">
                                          {" "}
                                          {
                                            match.exposures.find(
                                              (exposure) =>
                                                exposure.team === item
                                            )?.exposure
                                          }
                                        </span>
                                      </td>
                                    ))}
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.runner_name}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {moment(
                                        match?.created_date + "Z"
                                      ).format("DD MMM hh:mm:ss A")}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match.back_rate
                                        ? match?.back_rate
                                        : match?.lay_rate}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.amount}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.back_rate ? "LAGAI" : "KHAI"}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span
                                      style={{
                                        color: match?.profit
                                          ? "green"
                                          : "red",
                                      }}
                                      className="gx-text-red"
                                    >
                                      {match?.profit
                                        ? match?.profit
                                        : -match?.loss}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gx-py-1 gx-px-2 gx-bg-red gx-bg-flex gx-justify-content-between  gx-text-white ">
              <span />
              <span className="gx-font-weight-semi-bold gx-fs-sm">
                Fancy Bets
              </span>
              <span />
            </div>
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-bordered ant-table-ping-right ant-table-scroll-horizontal">
                    <div className="ant-table-container">
                      <div
                        className="ant-table-content"
                        style={{ overflow: "auto hidden" }}
                      >
                        <table
                          style={{
                            width: "auto",
                            minWidth: "100%",
                            tableLayout: "auto",
                          }}
                        >
                          <colgroup />
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Runner</th>
                              <th className="ant-table-cell">Date</th>
                              <th className="ant-table-cell">Rate</th>
                              <th className="ant-table-cell">Run</th>
                              <th className="ant-table-cell">Res</th>
                              <th className="ant-table-cell">Amount</th>
                              <th className="ant-table-cell">Mode</th>
                              <th className="ant-table-cell">P&amp;L</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            <tr
                              aria-hidden="true"
                              className="ant-table-measure-row"
                              style={{ height: 0, fontSize: 0 }}
                            >
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                            </tr>

                            {data.betResponseDTOList
                              ?.filter((item) => item?.gameType === "FANCY")
                              .map((match, index) => (
                                <tr
                                  data-row-key={0}
                                  className="ant-table-row ant-table-row-level-0"
                                >
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.runner_name}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {moment(
                                        match?.created_date + "Z"
                                      ).format("DD MMM hh:mm:ss A")}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match.back_rate
                                        ? match?.back_rate
                                        : match?.lay_rate}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match.back_rate
                                        ? match?.back_run
                                        : match?.lay_run}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.result}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match?.amount}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span className="gx-text-nowrap">
                                      {match.back_rate ? "yes" : "no"}
                                    </span>
                                  </td>
                                  <td className="ant-table-cell">
                                    <span
                                      style={{
                                        color: match?.profit
                                          ? "green"
                                          : "red",
                                      }}
                                      className="gx-text-nowrap gx-text-blue"
                                    >
                                      {match?.profit
                                        ? match?.profit
                                        : -match?.loss}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row ant-row-center gx-mt-5">
          <div className="ant-col ant-col-xs-24 ant-col-sm-14">
            <div className=" gx-px-2  ">
              <span className="gx-bg-grey gx-bg-flex gx-justify-content-center gx-py-1 gx-text-white">
                Match Plus Minus
              </span>
              <div className="gx-bg-flex gx-justify-content-center gx-bg-white gx-py-1 gx-text">
                <div className="gx-font-weight-semi-bold gx-fs-lg">
                  <span
                    style={{
                      color:
                        data?.betResponseDTOList
                          ?.filter((item) => item?.gameType === "MATCH")
                          .reduce(
                            (acc, item) =>
                              acc + (item.profit || 0) - (item.loss || 0),
                            0
                          ) >= 0
                          ? "green"
                          : "red",
                    }}
                  >
                    {data?.betResponseDTOList
                      ?.filter((item) => item?.gameType === "MATCH")
                      .reduce(
                        (acc, item) =>
                          acc + (item.profit || 0) - (item.loss || 0),
                        0
                      ) > 0
                      ? "YOU WON"
                      : "YOU LOSS"}{" "}
                    {Math.abs(
                      data?.betResponseDTOList
                        ?.filter((item) => item?.gameType === "MATCH")
                        .reduce(
                          (acc, item) =>
                            acc + (item.profit || 0) - (item.loss || 0),
                          0
                        )
                    )}{" "}
                    /- COINS
                  </span>
                </div>
              </div>
            </div>
            <div className=" gx-px-2  ">
              <span className="gx-bg-grey gx-bg-flex gx-justify-content-center gx-py-1 gx-text-white">
                Fancy Plus Minus
              </span>
              <div className="gx-bg-flex gx-justify-content-center gx-bg-white gx-py-1">
                <div className="">
                  <span
                    style={{
                      color:
                        data.betResponseDTOList
                          ?.filter((item) => item?.gameType === "FANCY")
                          ?.reduce(
                            (acc, item) =>
                              acc + (item.profit || 0) - (item.loss || 0),
                            0
                          ) >= 0
                          ? "green"
                          : "red",
                    }}
                    className=" gx-font-weight-semi-bold gx-fs-lg"
                  >
                    {data?.betResponseDTOList
                      ?.filter((item) => item?.gameType === "FANCY")
                      .reduce(
                        (acc, item) =>
                          acc + (item?.profit || 0) - (item.loss || 0),
                        0
                      ) > 0
                      ? "YOU WON"
                      : "YOU LOSS"}{" "}
                    {Math.abs(
                      data?.betResponseDTOList
                        ?.filter((item) => item?.gameType === "FANCY")
                        .reduce(
                          (acc, item) =>
                            acc + (item?.profit || 0) - (item.loss || 0),
                          0
                        )
                    )}
                    /- coins.
                  </span>
                </div>
              </div>
            </div>
            <div className=" gx-px-2  ">
              <span className="gx-bg-grey gx-bg-flex gx-justify-content-center gx-py-1 gx-text-white">
                Total Commission
              </span>
              <div className="gx-bg-flex gx-justify-content-center gx-bg-white gx-py-1 ">
                {" "}
                <div className="">
                  <span
                    style={{
                      color:
                        data?.profitLossAfterCommission - data?.totalWin >= 0
                          ? "green"
                          : "red",
                    }}
                    className=" gx-font-weight-semi-bold gx-fs-lg"
                  >
                    {data?.profitLossAfterCommission - data?.totalWin >= 0
                      ? "YOU WON"
                      : "YOU LOSS"}{" "}
                    {Math.abs(
                      data?.profitLossAfterCommission - data?.totalWin
                    )}
                    /- coins
                  </span>
                </div>
              </div>
            </div>
            <div className=" gx-px-2  ">
              <span className="gx-bg-grey gx-bg-flex gx-justify-content-center gx-py-1 gx-text-white">
                Net Plus Minus
              </span>
              <div className="gx-bg-flex gx-justify-content-center gx-bg-white gx-py-1 gx-text-blue">
                <div className="">
                  <span
                    style={{
                      color:
                        data?.profitLossAfterCommission >= 0
                          ? "green"
                          : "red",
                    }}
                    className=" gx-font-weight-semi-bold gx-fs-lg"
                  >
                    {data?.profitLossAfterCommission > 0
                      ? "YOU WON"
                      : "YOU LOSS"}{" "}
                    {Math.abs(data?.profitLossAfterCommission)}/- coins.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row ant-row-center gx-py-2">
          <div className="ant-col ant-col-sm-16">
            <div className="gx-py-1 gx-px-2 gx-bg-red gx-bg-flex gx-justify-content-between  gx-text-white ">
              <span />
              <span className="gx-font-weight-semi-bold gx-fs-sm">
                Rejected Bets
              </span>
              <span />
            </div>
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-bordered ant-table-empty ant-table-ping-right ant-table-scroll-horizontal">
                    <div className="ant-table-container">
                      <div
                        className="ant-table-content"
                        style={{ overflow: "auto hidden" }}
                      >
                        <table
                          style={{
                            width: "auto",
                            minWidth: "100%",
                            tableLayout: "auto",
                          }}
                        >
                          <colgroup />
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Runner</th>
                              <th className="ant-table-cell">Date</th>
                              <th className="ant-table-cell">Rate</th>
                              <th className="ant-table-cell">Run</th>
                              <th className="ant-table-cell">Res</th>
                              <th className="ant-table-cell">Amount</th>
                              <th className="ant-table-cell">Mode</th>
                              <th className="ant-table-cell">P&amp;L</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            <tr
                              aria-hidden="true"
                              className="ant-table-measure-row"
                              style={{ height: 0, fontSize: 0 }}
                            >
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td
                                style={{ padding: 0, border: 0, height: 0 }}
                              >
                                <div
                                  style={{ height: 0, overflow: "hidden" }}
                                >
                                  &nbsp;
                                </div>
                              </td>
                            </tr>
                            <tr className="ant-table-placeholder">
                              <td colSpan={8} className="ant-table-cell">
                                <div
                                  className="ant-table-expanded-row-fixed"
                                  style={{
                                    width: 360,
                                    position: "sticky",
                                    left: 0,
                                    overflow: "hidden",
                                  }}
                                >
                                  <div className="gx-text-center gx-text-black">
                                    No data found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-sm-15">
            <div className="gx-py-4">
              <a href="/main/dashboard/">
                <div className="gx-bg-grey gx-py-1 gx-text-white gx-font-weight-semi gx-bg-flex gx-justify-content-center">
                  BACK TO MAIN MENU
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
