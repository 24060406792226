import React from "react";
import { useEffect } from "react";

import { searchSubstringInArrayObjects } from "../utils/constants";
import { useDispatch } from "react-redux";

import moment from "moment";

import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
} from "../service/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import CustomInput from "./components/CustomInput";
import CustomSelect from "./components/CustomSelect";
import { Button } from "react-bootstrap";

export default function Ledger() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const navigate = useNavigate();
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = React.useState(false);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const { response } = await getAccountStatementAPI(payload);
    setNoOfPages(Math.ceil(response.length / rowsPerPage));
    setCurrentPageData(response.slice(0, rowsPerPage));

    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);
  const handleSubmit = async () => {
    getAccountStatement();
  };
  const isMobileView = window.innerWidth < 768;
  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ paddingBottom: 120 }}>
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-22 ant-col-lg-16 ant-col-xl-16">
            <div className="gx-py-1 gx-bg-grey ">
              <div className="gx-bg-flex gx-justify-content-center gx-text-white gx-font-weight-semi-bold">
                MY LEDGER
              </div>
            </div>
            <div className="ant-row ant-row-center gx-py-3  gx-fs-sm gx-font-weight-semi-bold gx-text-light-gray">
              <div className="ant-col gx-py-md-1 gx-py-1 ant-col-xs-10 ant-col-sm-8">
                <span className="gx-font-weight-semi-bold gx-fs-lg gx-px-2">
                  {" "}
                  Lena:
                </span>
                <span className="gx-fs-lg gx-font-weight-semi-bold  gx-text-green-0">
                  {data?.reduce(
                    (acc, item) =>
                      item.creditInfo
                        ? acc + parseFloat(item.creditInfo)
                        : acc,
                    0
                  )}
                </span>
              </div>
              <div className="ant-col gx-bg-flex gx-py-md-1 gx-py-1 gx-justify-content-center  ant-col-xs-10 ant-col-sm-8">
                <span className="gx-font-weight-semi-bold gx-fs-lg gx-px-2">
                  {" "}
                  Dena:
                </span>
                <span className="gx-fs-lg gx-font-weight-semi-bold   gx-text-red">
                  {data?.reduce(
                    (acc, item) =>
                      item.debitInfo ? acc + parseFloat(item.debitInfo) : acc,
                    0
                  )}
                </span>
              </div>
              <div className="ant-col gx-py-md-1 gx-py-1 ant-col-xs-8 ant-col-sm-8">
                <span className="gx-font-weight-semi-bold gx-fs-lg gx-px-2 ">
                  {" "}
                  Balance:
                </span>
                <span className="gx-fs-lg gx-font-weight-semi-bold  gx-text-red">
                  {data?.reduce(
                    (acc, item) =>
                      item.updatedBalance
                        ? acc + parseFloat(item.updatedBalance)
                        : acc,
                    0
                  )}
                </span>
              </div>
            </div>
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-bordered">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        <table style={{ tableLayout: "auto" }}>
                          <colgroup />
                          <thead className="ant-table-thead">
                            <tr>
                              <th
                                className="ant-table-cell llllll "
                                style={{ textAlign: "center" }}
                              >
                                DESCRIPTION
                              </th>
                              <th
                                className="ant-table-cell llllll"
                                style={{ textAlign: "center" }}
                              >
                                WON BY
                              </th>
                              <th
                                className="ant-table-cell llllll"
                                style={{ textAlign: "center" }}
                              >
                                DENA
                              </th>
                              <th
                                className="ant-table-cell llllll "
                                style={{ textAlign: "center" }}
                              >
                                LENA
                              </th>
                              <th
                                className="ant-table-cell llllll "
                                style={{ textAlign: "center" }}
                              >
                                HISAB
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            {data
                              ?.sort(
                                (a, b) =>
                                  new Date(b.createdAt) -
                                  new Date(a.createdAt)
                              )
                              ?.map((item) => (
                                <tr
                                  data-row-key="66fbf5ceb806a55a63c864db-0"
                                  className="ant-table-row ant-table-row-level-0"
                                >
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{ textAlign: "center" }}
                                  >
                                    <a
                                      onClick={() =>
                                        item?.sport == "CRICKET"
                                          ? navigate(
                                              `/complete_detail/${
                                                item?.accountLogId
                                              }`,
                                              {
                                                state: {
                                                  item,
                                                },
                                              }
                                            )
                                          : navigate(`/casino/casino-bets`)
                                      }
                                    >
                                      <span className="gx-text-link">
                                        {item.comment}
                                      </span>
                                    </a>
                                  </td>
                                  <td
                                    className="ant-table-cell llllll"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="">{item?.wonBy}</span>
                                  </td>
                                  <td
                                    className="ant-table-cell llllll"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="gx-text-red">
                                      {item?.debitInfo}
                                    </span>
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="gx-text-green-0">
                                      {item?.creditInfo}
                                    </span>
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="gx-text-red">
                                      {parseFloat(
                                        item?.updatedBalance
                                      ).toFixed(2)}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gx-py-4">
          <div className="ant-row ant-row-center">
            <div className="ant-col ant-col-xs-24 ant-col-sm-18 ant-col-md-16 ant-col-lg-10 ant-col-xl-14 ant-col-xxl-14">
              <a href="/main/dashboard/">
                <div className="gx-bg-grey gx-py-2 gx-text-white gx-font-weight-semi gx-bg-flex gx-justify-content-center">
                  BACK TO MAIN MENU
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
