export const cricketMatchOddsPL = (odds, betAmount, type = "back") => {
  //back
  //profit -> (odds - 1) * betAMount loss->betAmount
  //lay ->viceverssa
  let profit;
  let loss;
  if (type == "back") {
    profit = (odds - 1) * betAmount;
    loss = betAmount;
  } else {
    loss = (odds - 1) * betAmount;
    profit = betAmount;
  }
  return {
    profit,
    loss,
  };
};
export const cricketBookMakerPL = (odds, betAmount, type = "back") => {
  //if its 2 digit number divide it by 100
  //back
  //after this profit-> number*betAmount
  //loss
  //bet Amount
  //lay ->vice versa
  let profit;
  let loss;
  if (type == "back") {
    profit = odds / 100;
    profit = profit * betAmount;

    loss = betAmount;
  } else {
    loss = odds / 100;
    loss = loss * betAmount;
    profit = betAmount;
  }

  return {
    profit,
    loss,
  };
};
export const cricketFancyPL = (size, betAmount, type = "no") => {
  //no
  //profit -> betAMount
  //loss-> size/100 * betamount
  //vice versa
  let profit;
  let loss;
  if (type == "no") {
    profit = betAmount;
    loss = (size / 100) * betAmount;
  } else {
    profit = (size / 100) * betAmount;
    loss = betAmount;
  }
  return {
    profit,
    loss,
  };
};
export const casinoPL = (odds, betAmount, type = "back") => {
  let profit;
  let loss;
  if (type == "back") {
    profit = (odds - 1) * betAmount;
    loss = betAmount;
  } else {
    loss = (odds - 1) * betAmount;
    profit = betAmount;
  }
  return {
    profit,
    loss,
  };
};
export const tennisAndSoccerPL = (odds, betAmount, type = "back") => {
  let profit;
  let loss;
  if (type == "back") {
    profit = (odds - 1) * betAmount;
    loss = betAmount;
  } else {
    loss = (odds - 1) * betAmount;
    profit = betAmount;
  }
  return {
    profit,
    loss,
  };
};
