import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { Link, useLocation } from "react-router-dom";

import { getCasinoBetReportAPI } from "../../service/AuthService";
import { CASINOS } from "../../utils/constants";

export default function CasinoMyBets() {
  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const { state } = useLocation();
  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);

    const { response } = await getCasinoBetReportAPI();

    if (selectedType == "ALL") {
      setData(response);
    } else {
      setData(
        response.filter(
          (item) => item?.casino?.toLowerCase() === selectedType?.toLowerCase()
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [selectedType]);
 
  return (
    <div className="position-relative">
      <main className="warp table-responsive ">
        <div align="center" className="menu">
          <select
            onChange={(e) => setSelectedType(e.target.value)}
            style={{ width: "90%", color: "black", margin: 5, padding: 5 }}
          >
            <option value="ALL">ALL</option>
            {CASINOS.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        <div
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "250px",
          }}
          className="ant-table ant-table-bordered"
        >
          <div className="ant-table-container">
            <div
              style={{
                overflow: "auto",
              }}
              className="ant-table-content"
            >
              <table style={{ tableLayout: "auto" }}>
                <colgroup />
                <thead className="ant-table-thead">
                  <tr>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      SR
                    </th>
                    <th
                      className="ant-table-cell llllll"
                      style={{ textAlign: "center" }}
                    >
                      CASINO
                    </th>
                    <th
                      className="ant-table-cell llllll"
                      style={{ textAlign: "center" }}
                    >
                      RUNNER NAME
                    </th>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      AMOUNT
                    </th>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      RATE
                    </th>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      PROFIT/LOSS
                    </th>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      WONBY
                    </th>
                    <th
                      className="ant-table-cell llllll "
                      style={{ textAlign: "center" }}
                    >
                      DATE
                    </th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {data
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    ?.map((item, index) => (
                      <tr
                        data-row-key="66fbf5ceb806a55a63c864db-0"
                        className="ant-table-row ant-table-row-level-0"
                      >
                        <td
                          className="ant-table-cell llllll "
                          style={{ textAlign: "center" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="ant-table-cell llllll "
                          style={{ textAlign: "center" }}
                        >
                          {item?.casino}
                        </td>
                        <td
                          className="ant-table-cell llllll"
                          style={{ textAlign: "center" }}
                        >
                          {item?.runnerName}
                        </td>
                        <td
                          className="ant-table-cell llllll"
                          style={{ textAlign: "center" }}
                        >
                          <span className="gx-text-black">
                            {parseFloat(item?.amount).toFixed(2)}
                          </span>
                        </td>
                        <td
                          className="ant-table-cell llllll "
                          style={{ textAlign: "center" }}
                        >
                          <span className="gx-text-black">
                            {parseFloat(item?.backRate).toFixed(2)}
                          </span>
                        </td>
                        <td
                          className="ant-table-cell llllll "
                          style={{ textAlign: "center" }}
                        >
                          <span
                            style={{
                              color:
                                parseFloat(item?.profitLoss) > 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {parseFloat(item?.profitLoss).toFixed(2)}
                          </span>
                        </td>
                        <td
                          className="ant-table-cell llllll "
                          style={{ textAlign: "center" }}
                        >
                          <span>{item?.wonBy}</span>
                        </td>
                        <td>
                          <span className="gx-text-black">
                            {moment(item?.createdAt + "Z").format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <table
          style={{ color: "black" }}
          className=" sesstion-bets table-bordered text-center w-100 my-2 mt-0"
        >
          <thead className="ant-table-thead">
            <tr>
              <th
                className="ant-table-cell llllll "
                style={{ textAlign: "center" }}
              >
                CASINO BETS
              </th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr>
              <th
                className="ant-table-cell llllll "
                style={{ textAlign: "center" }}
              >
                CASINO BETS
              </th>
            </tr>
            <tr>
              <th
                width="15%"
                align="center"
                valign="middle"
                className="FontTextWhite10px cell-td pdr-4"
              >
                SR
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td pdr-4"
              >
                CASINO
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                RUNNER NAME
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                AMOUNT
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                RATE
              </th>

              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                PROFIT/LOSS
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                WONBY
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                style={{
                  backgroundColor: "var(--secondary)",
                  color: "white",
                }}
                className="FontTextWhite10px cell-td"
              >
                DATE
              </th>
            </tr>
            {data
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.filter((item) => {
                if (selectedType == "ALL") return true;
                return (
                  item?.casino?.toLowerCase() === selectedType?.toLowerCase()
                );
              })

              .map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.casino}</td>
                  <td>{item?.runnerName}</td>
                  <td>{item?.amount}</td>
                  <td>{item?.backRate}</td>

                  <td>{item?.profitLoss}</td>
                  <td>{item?.wonBy}</td>
                  <td>
                    {moment(item?.createdAt + "Z").format(
                      "DD/MM/YYYY hh:mm A"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}
