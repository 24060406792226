import React from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";

export default function LedgerInfoMatch() {
    return (
        <>
            <Header />
            <div className="container ledger-table-container-3">
                <div className="ledger-info">
                    <div class="bet-place-tbl-th">Match Plus Minus</div>
                    <div class="bet-place-tbl-td text-red">You Lost 0.0/- Coins.</div>
                </div>
                <div className="ledger-info">
                    <div class="bet-place-tbl-th">Match Plus Minus</div>
                    <div class="bet-place-tbl-td text-red">You Lost 0.0/- Coins.</div>
                </div>
                <div className="ledger-info">
                    <div class="bet-place-tbl-th">Amount After Comm. & Share</div>
                    <div class="bet-place-tbl-td text-red">You Lost 0.0/- Coins.</div>
                </div>
                <div className="ledger-info">
                    <div class="bet-place-tbl-th">Mob. App. Charges</div>
                    <div class="bet-place-tbl-td text-red">You Lost 0.0/- Coins.</div>
                </div>
                <div className="ledger-info">
                    <div class="bet-place-tbl-th">Net Plus Minus</div>
                    <div class="bet-place-tbl-td text-red">You Lost 0.0/- Coins.</div>
                </div>

                <Link to='/ledgers' className="ledger-info">
                    <div class="bet-place-tbl-th">BACK TO LIST</div>
                </Link>
            </div>
        </>
    )
}