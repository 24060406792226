import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "./components/CustomInput";
import CustomSelect from "./components/CustomSelect";
import CustomTable from "./components/Table";

import {
  bgColor,
  buttonColors,
  convertEpochToDate,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../utils/constants";
import { SearchIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";

import moment from "moment";
//   import "../assets/pagination.css";
import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCoinHistoryAPI,
} from "../service/AuthService";
export default function AccountStatement() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const isMobileView = window.innerWidth < 768;
  const getData = async (id, TYPE = "ALL") => {
    setSelectedLogId(id);
    const { response } = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;

    if (TYPE == "ALL") {
      response_ = response;
    }
    if (TYPE == "BACK") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "back"
      );
    }
    if (TYPE == "LAY") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "lay"
      );
    }
    setLogData(response_);
  };

  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(moment(startDate).subtract("M", 7)).getTime(),
      endDate: new Date(endDate).getTime(),
      type: type,
    };
    const {
      response: { coinHistory },
    } = await getCoinHistoryAPI(payload);
    setNoOfPages(Math.ceil(coinHistory.length / rowsPerPage));
    setCurrentPageData(coinHistory.slice(0, rowsPerPage)?.reverse());

    const reversedData = [];
    for (let i = coinHistory.length - 1; i >= 0; i--) {
      reversedData.push(coinHistory[i]);
    }
    if (type == "ALL") {
      setData(reversedData);
    } else if (type == "P&L") {
      setData(
        reversedData?.filter(
          (item) =>
            item?.description?.includes("Profit") ||
            item?.description?.includes("Loss")
        )
      );
      setCurrentPageData(
        reversedData
          ?.filter(
            (item) =>
              !item?.description?.includes("Deposit") &&
              !item?.description?.includes("Withdraw")
          )
          .slice(
            pageNumber * rowsPerPage,
            pageNumber * rowsPerPage + rowsPerPage
          )
      );
    } else {
      setData(
        reversedData?.filter(
          (item) =>
            item?.description?.includes("Deposit") ||
            item?.description?.includes("Withdraw")
        )
      );
      setCurrentPageData(
        reversedData
          ?.filter(
            (item) =>
              item?.description?.includes("Deposit") ||
              item?.description?.includes("Withdraw")
          )
          .slice(
            pageNumber * rowsPerPage,
            pageNumber * rowsPerPage + rowsPerPage
          )
      );
    }
  };
  const search = async (e) => {
    if (e.target.value == "") return getAccountStatement();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };
  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, rowsPerPage, type]);
  return (
    <main
      style={{
        height: "100vh",
        overflow: "auto",
      }}
      className="ant-layout-content gx-layout-content   "
    >
      <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
        <div className="ant-row ant-row-center">
          <div
            style={{
              marginBottom: 150,
            }}
            className="ant-col gx-col-full  ant-col-xs-24 ant-col-sm-16"
          >
            <div className="gx-py-2 gx-bg-grey gx-bg-flex gx-justify-content-center gx-text-white gx-w-100">
              MY ACCOUNT STATEMENT ({data?.length})
            </div>
            <div className="ant-row ant-row-space-between   gx-bg-flex  gx-align-items-center gx-w-100 gx-py-1">
              <div className="ant-col gx-mt-3 gx-py-md-0 gx-py-2 gx-px-4 ant-col-xs-24 ant-col-sm-8">
                <div
                  className="ant-picker ant-picker-range gx-border-redius0 gx-ml-2 gx-w-100"
                  style={{ width: 300 }}
                >
                  <div className="ant-picker-input ant-picker-input-active">
                    <input
                      readOnly=""
                      placeholder="Start date"
                      size={12}
                      type="date"
                      autoComplete="off"
                      onChange={(e) => setStartDate(e.target.value)}
                      value={startDate}
                      defaultValue="2024-10-01"
                    />
                  </div>
                  <div className="ant-picker-range-separator">
                    <span aria-label="to" className="ant-picker-separator">
                      <span
                        role="img"
                        aria-label="swap-right"
                        className="anticon anticon-swap-right"
                      >
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="swap-right"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="ant-picker-input">
                    <input
                      readOnly=""
                      placeholder="End date"
                      size={12}
                      autoComplete="off"
                      onChange={(e) => setEndDate(e.target.value)}
                      type="date"
                      value={endDate}
                      defaultValue="2024-10-31"
                    />
                  </div>
                </div>
              </div>
              <div className="ant-col   gx-text-white gx-fs-lg gx-py-md-0 gx-py-2 gx-font-weight-bold gx-bg-flex gx-align-items-center gx-justify-content-start  ant-col-xs-24 ant-col-sm-12">
                <div className="ant-radio-group ant-radio-group-outline ant-radio-group-small gx-bg-flex gx-w-100 gx-py- gx-my-0 gx-align-items-center gx-justify-content-center  ">
                  {["ALL", "P&L", "Account"].map((item) => (
                    <label
                      className={`ant-radio-button-wrapper ${
                        type == item ? "gx-bg-dark" : "gx-bg-primary"
                      } gx-text-white gx-py- gx-my-0`}
                    >
                      <span
                        onClick={() => setType(item)}
                        className="ant-radio-button"
                      >
                        <input
                          className="ant-radio-button-input"
                          type="radio"
                          value={item}
                          checked={selectedType == item}
                          defaultValue={selectedType == item ? 1 : 0}
                        />
                        <span className="ant-radio-button-inner" />
                      </span>
                      <span>{item}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="ant-table-wrapper gx-table-responsive ">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-bordered">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        <table style={{ tableLayout: "auto" }}>
                          <colgroup />
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell llllll gx-text-nowrap">
                                Date
                              </th>
                              <th className="ant-table-cell llllll gx-text-nowrap">
                                Description
                              </th>
                              <th className="ant-table-cell llllll gx-text-nowrap">
                                CREDIT
                              </th>
                              <th className="ant-table-cell llllll gx-text-nowrap">
                                DEBIT
                              </th>
                              <th className="ant-table-cell llllll gx-text-nowrap">
                                Balance
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            {currentPageData.map((item, index) => (
                              <tr
                                data-row-key="2024-10-01T15:31:59.333Z"
                                className="ant-table-row ant-table-row-level-0"
                              >
                                <td className="ant-table-cell llllll gx-text-nowrap">
                                  {moment(item.createdAt + "Z").format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </td>
                                <td className="ant-table-cell llllll gx-text-nowrap">
                                  <div className="truncate">
                                    {item.description}
                                  </div>
                                </td>
                                <td className="ant-table-cell llllll gx-text-nowrap">
                                  {item.credit}
                                </td>
                                <td className="ant-table-cell llllll gx-text-nowrap">
                                  {item.debit}
                                </td>
                                <td className="ant-table-cell llllll gx-text-nowrap">
                                  <div className="gx-text-black gx-font-weight-bold gx-fs-md">
                                    {parseInt(item.updatedBalance)?.toFixed(
                                      2
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul className="ant-pagination gx-mt-3">
              <li
                onClick={() => {
                  setPageNumber(
                    pageNumber - 1 >= 0 ? pageNumber - 1 : pageNumber
                  );
                }}
                title="Previous Page"
                className="ant-pagination-prev ant-pagination-disabled"
                aria-disabled="true"
              >
                <a
                  className="gx-px-2 gx-fs-sm gx-py-2 gx-bg-white gx-border gx-border-info"
                  disabled=""
                >
                  Prev
                </a>
              </li>
              <li
                title={1}
                className="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active"
                tabIndex={0}
              >
                <a rel="nofollow">{pageNumber + 1}</a>
              </li>
              <li
                onClick={() => {
                  setPageNumber(
                    pageNumber + 1 < noOfPages ? pageNumber + 1 : pageNumber
                  );
                }}
                title="Next Page"
                className="ant-pagination-next ant-pagination-disabled"
                aria-disabled="true"
              >
                <a
                  className="gx-px-2 gx-fs-sm gx-py-2 gx-border gx-bg-white gx-border-info"
                  disabled=""
                >
                  Next
                </a>
              </li>
            </ul>
            <div className="gx-py-4">
              <a href="/main/dashboard/">
                <div className="gx-bg-grey gx-py-1 gx-text-white gx-font-weight-semi gx-bg-flex gx-justify-content-center">
                  BACK TO MAIN MENU
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
