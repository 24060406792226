import React from "react";

export default function FreeGames() {
  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
        <div className="ant-row ant-row-center gx-mb-4">
          <div className="ant-col gx-col-full gx-bg-flex gx-justify-content-center ant-col-xs-24 ant-col-sm-24 ant-col-lg-6 ant-col-xl-6">
            <div className=" gx-w-100">
              <img
                src="https://tvspro99.com/assets/images/free-game.jpg"
                alt="Free Games"
                className="gx-w-100"
                height={250}
              />
            </div>
          </div>
        </div>
        <div className="ant-row ant-row-center">
          <div className="ant-col gx-col-full ant-col-xs-24 ant-col-sm-24 ant-col-lg-10 ant-col-xl-10">
            <div className="gx-my-4">
              <iframe
                src="https://doodlecricket.github.io/#/"
                title=" "
                loading="lazy"
                className="gx-w-100 "
                style={{ height: 400 }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
