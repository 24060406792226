import React, { useEffect } from "react";
import Header from "./components/Header";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SvgComponent from "./components/SvgComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";
import MatkaDashboard from "./matka/MatkaDashboard";
import { getAllSoccerMatchListAPI } from "../service/soccer&tennis";

const Matches = ({ isUpcoming }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedGame, setSelectedGame] = React.useState(
    location.pathname.includes("casino") ? "casino" : "cricket"
  );

  const userData = useSelector((state) => state.account.userData);

  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => {
        if (isUpcoming) {
          return !item?.inPlay;
        } else {
          return true;
        }
      })
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );

  const naviagte = useNavigate();

  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();

      if (code == 200) {
        let counts = await getBetCount();
        response.map((item) => {
          let countData =
            counts?.betCount[
              Object.keys(counts.betCount).find(
                (count) => count == item?.cricketId
              )
            ];

          item.matchCount = countData?.matchCount;
          item.fancyCount = countData?.fancyCount;
          return item;
        });

        dispatch({
          type: "gameData/setCricketGames",
          payload: response,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let interval;
    getCricketMatches();
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };

  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div
        className="gx-main-content-wrapper"
        style={{ paddingBottom: 220, height: "100vh" }}
      >
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-md-19">
            <div className="ant-row">
              <div className="ant-col gx-bg-grey gx-py-2 gx-text-white gx-fs-xxl gx-font-weight-semi-bold ant-col-xs-24">
                Active Matches
              </div>
            </div>
            {cricketData
              ?.filter((i) => i.inPlay)
              ?.map((item) => (
                <a href={`/main/match-deatils/${item?.cricketId}`}>
                  <div className="ant-row ant-row-center gx-bg-flex gx-px-2 gx-py-3  gx-box-shadow gx-align-items-center gx-justify-content-start">
                    <div className="ant-col ant-col-lg-5">
                      <div className="gx-fs-md gx-d-none gx-d-lg-block gx-font-weight-semi-bold gx-pb-1 gx-text-black">
                        {item?.eventName}
                      </div>
                      <div
                        className="gx-px-1  gx-bg-grey responsive-text  gx-text-white gx-py-2"
                        style={{
                          borderTopRightRadius: 100,
                          borderBottomRightRadius: 100,
                        }}
                      >
                        <div className="gx-text-truncate gx-my-1 gx-font-weight-semi-bold ">
                          {item?.eventName}
                        </div>
                        <div className="">
                          {moment(item?.eventTime).format(
                            "DD-MM-YYYY HH:mm A"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5">
                      {item?.inPlay && (
                        <div className="newBlinking gx-mx-1 " />
                      )}

                      <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 256 256"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                        </svg>
                      </span>

                      <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                        B
                      </span>
                      <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                        F
                      </span>
                    </div>
                    <div className="ant-col gx-bg-flex gx-my-1 ant-col-lg-12">
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back1}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay1}
                      </div>
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back11}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay11}
                      </div>
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back12}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay12}
                      </div>
                    </div>
                    <div className="gx-fs-md gx-px-2 gx-d-lg-none gx-d-block gx-font-weight-semi-bold gx-pb-1">
                      {item?.eventName}
                    </div>
                  </div>
                </a>
              ))}
            {cricketData
              ?.filter((i) => !i.inPlay)
              ?.map((item) => (
                <a href={`/main/match-deatils/${item?.cricketId}`}>
                  <div className="ant-row ant-row-center gx-bg-flex gx-px-2 gx-py-3  gx-box-shadow gx-align-items-center gx-justify-content-start">
                    <div className="ant-col ant-col-lg-5">
                      <div className="gx-fs-md gx-d-none gx-d-lg-block gx-font-weight-semi-bold gx-pb-1 gx-text-black">
                        {item?.eventName}
                      </div>
                      <div
                        className="gx-px-1  gx-bg-grey responsive-text  gx-text-white gx-py-2"
                        style={{
                          borderTopRightRadius: 100,
                          borderBottomRightRadius: 100,
                        }}
                      >
                        <div className="gx-text-truncate gx-my-1 gx-font-weight-semi-bold ">
                          {item?.eventName}
                        </div>
                        <div className="">
                          {moment(item?.eventTime).format(
                            "DD-MM-YYYY HH:mm A"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5">
                      {item?.inPlay && (
                        <div className="newBlinking gx-mx-1 " />
                      )}

                      <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 256 256"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                        </svg>
                      </span>

                      <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                        B
                      </span>
                      <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                        F
                      </span>
                    </div>
                    <div className="ant-col gx-bg-flex gx-my-1 ant-col-lg-12">
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back1}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay1}
                      </div>
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back11}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay11}
                      </div>
                      <div
                        className="inplayElement matchdtailsYesBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.back12}
                      </div>
                      <div
                        className="inplayElement matchdtailsNoBackground"
                        style={{ width: "9rem", gap: 3 }}
                      >
                        {item?.lay12}
                      </div>
                    </div>
                    <div className="gx-fs-md gx-px-2 gx-d-lg-none gx-d-block gx-font-weight-semi-bold gx-pb-1">
                      {item?.eventName}
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Matches;
