import React, { useState } from 'react';
import Header from './components/Header';
import { useDispatch, useSelector } from "react-redux";

import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';

const ChangePassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  // const toast = {};
  const dispatch = useDispatch();

  const changePassword = async () => {
    if (password === "" || confirmPassword === "" || oldPassword === "") {
      toast.error('Please fill all the fields');

      return;
    }
    if (password !== confirmPassword) {
      toast.error('Password and confirm password does not match');
      return;
    }
    if (password === oldPassword) {
      toast.error('New password and old password cannot be same');
      return;
    }
      if(password.length !== 6) {
      toast.error('Password must be exactly 6 characters long.');
      return;
    }

    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    if (code === 200) {
      toast.success('Password changed successfully');
      window.location.href = "/";
      // toast({
      //   title: "Success",
      //   description: "Password changed successfully",
      //   status: "success",
      //   duration: 4000,
      //   isClosable: true,
      // });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    } else {
      // toast({
      //   title: "Error",
      //   description: "Wrong Password",
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    }
  };
  
  return (
    <main className="ant-layout-content gx-layout-content   ">
    <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
      <div className="ant-row ant-row-center">
        <div className="ant-col ant-col-xs-20 ant-col-sm-14 ant-col-md-11 ant-col-lg-7 ant-col-xl-5 ant-col-xxl-5">
          <div className="gx-rounded-sm gx-bg-grey-revers">
            <div
              className="gx-bg-grey-revers gx-py-3 gx-rounded-lg gx-bg-flex gx-justify-content-center gx-align-content-center gx-text-white"
              style={{ height: 70 }}
            >
              <div className="gx-h-100 gx-bg-flex gx-justify-content-center gx-align-content-center gx-fs-xl gx-pt-2">
                Change Password
              </div>
            </div>
            <div className="gx-px-4 gx-bg-grey-revers">
              <div className="ant-form ant-form-vertical gx-py-3">
                <div className="ant-form-item">
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-control">
                      <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                          <input
                            placeholder="Enter Old Password"
                            id="oldPassword"
                            aria-required="true"
                              type="password"
                              onChange={(e) => setOldPassword(e.target.value)}
                              value={oldPassword}
                            className="ant-input ant-input-password gx-border-redius"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-form-item">
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-control">
                      <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                          <input
                            placeholder="Enter New Password"
                            id="newPassword"
                            aria-required="true"
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            className="ant-input ant-input-password gx-border-redius"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-form-item">
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-control">
                      <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                          <input
                            placeholder="Enter Confirm Password"
                            id="confirmPassword"
                            aria-required="true"
                              type="password"
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              value={confirmPassword}
                            className="ant-input ant-input-password gx-border-redius"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-form-item">
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-control">
                      <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                          <div className="gx-bg-flex gx-justify-content-center">
                            <button
                                type="submit"
                                onClick={changePassword}
                              className="ant-btn ant-btn-danger gx-rounded-xxl gx-my-2 gx-text-white gx-bg-flex gx-justify-content-center "
                            >
                              <span>Done</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  
  );
};

export default ChangePassword;
