import React, { useEffect, useRef } from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

const Timer = ({ value }) => {
  const tickRef = useRef(null);
  let tickInstance = useRef(null);

  useEffect(() => {
    tickInstance.current = Tick.DOM.create(tickRef.current, {
      value: value ? value : 0,
    });
    Tick.count.down("2021");

    return () => {
      if (tickInstance.current) {
        Tick.DOM.destroy(tickRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (tickInstance.current) {
      tickInstance.current.value = value;
    }
  }, [value]);

  return (
    <div ref={tickRef} className="tick">
      <div data-repeat="true" aria-hidden="true">
        <span data-view="flip">Tick</span>
      </div>
    </div>
  );
};

export default Timer;
