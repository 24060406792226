import React, { useEffect } from "react";
import logo from "../../../src/assets/logo.png";

export default function Loader() {
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes flipHorizontal {
        0% {
          transform: rotateY(0);
        }
        50% {
          transform: rotateY(180deg);
        }
        100% {
          transform: rotateY(360deg);
        }
      }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        position: "absolute",
        zIndex: 9999,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="loader"
    >
      <img
        src={logo}
        alt="Loader"
        style={{
          animation: "flipHorizontal 1s ease-in-out infinite",
          width: "70%",
          height: "10%",
        }}
      />
    </div>
  );
}
