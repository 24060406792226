import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dashboardBannerAPI } from "../service/AuthService";
import Matches from "./Matches";

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
        <div
          className="ant-row gx-flex-gap-150 gx-bg-flex gx-mb-1 ant-row-no-wrap gx-justify-content-center"
          style={{ marginLeft: "-10px", marginRight: "-10px", rowGap: 20 }}
        >
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/matches">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-inplay.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  Inplay
                </div>
              </div>
            </a>
          </div>
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/casino">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-casino.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  casino
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          className="ant-row gx-flex-gap-150 gx-bg-flex gx-mb-1 ant-row-no-wrap gx-justify-content-center"
          style={{ marginLeft: "-10px", marginRight: "-10px", rowGap: 20 }}
        >
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/profile">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-profile.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  Profile
                </div>
              </div>
            </a>
          </div>
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/statement">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-statement.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  statement
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          className="ant-row gx-flex-gap-150 gx-bg-flex gx-mb-1 ant-row-no-wrap gx-justify-content-center"
          style={{ marginLeft: "-10px", marginRight: "-10px", rowGap: 20 }}
        >
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/freegame">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-freegames.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  free games
                </div>
              </div>
            </a>
          </div>
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/rules">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-rules.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  rules
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          className="ant-row gx-flex-gap-150 gx-bg-flex gx-mb-1 ant-row-no-wrap gx-justify-content-center"
          style={{ marginLeft: "-10px", marginRight: "-10px", rowGap: 20 }}
        >
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/ledger">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-ledger1.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase">
                  my ledger
                </div>
              </div>
            </a>
          </div>
          <div
            className="ant-col"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <a href="/main/changepassword">
              <div
                className="gx-bg-flex gx-flex-column gx-justify-content-center gx-align-items-center"
                style={{ width: 100 }}
              >
                <img alt="inage" src="/assets/images/tvs-pro-password.svg" />
                <div className="gx-font-weight-bold gx-text-black gx-text-center gx-text-uppercase ">
                  PASSWORD
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
