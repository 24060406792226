import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Profile() {
  const userData = useSelector((state) => state.account.userData);
  console.log(userData);
  const [isOn, setIsOn] = useState(true);
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <div className="gx-main-content-wrapper" style={{ marginBottom: 120 }}>
      <div className="ant-row ant-row-center">
        <div className="ant-col gx-col-full ant-col-xs-24 ant-col-sm-24 ant-col-md-20 ant-col-xl-14 ant-col-xxl-14">
          <div className="ant-table-wrapper gx-table-responsive">
            <div className="ant-spin-nested-loading">
              <div className="ant-spin-container">
                <div className="ant-table ant-table-small ant-table-bordered">
                  <div className="ant-table-container">
                    <div className="ant-table-content">
                      <table style={{ tableLayout: "auto" }}>
                        <colgroup />
                        <thead className="ant-table-thead">
                          <tr>
                            <th colSpan={3} className="ant-table-cell">
                              Rate Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr
                            data-row-key={1}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Rate Difference</td>
                            <td
                              className="ant-table-cell"
                              style={{ textAlign: "center" }}
                            >
                              <div
                                className="ant-select ant-select-sm ant-select-single ant-select-show-arrow ant-select-show-search"
                                // style={{ width: 50 }}
                              >
                                <div className="ant-select-selector">
                                  <span className="ant-select-selection-search">
                                    <input
                                      type="search"
                                      autoComplete="off"
                                      className="ant-select-selection-search-input"
                                      role="combobox"
                                      aria-haspopup="listbox"
                                      aria-owns="rc_select_0_list"
                                      aria-autocomplete="list"
                                      aria-controls="rc_select_0_list"
                                      aria-activedescendant="rc_select_0_list_0"
                                      defaultValue=""
                                      id="rc_select_0"
                                    />
                                  </span>
                                  <select
                                    className="ant-select-selection-item"
                                    style={{ border: "none" }}
                                  >
                                    {[1, 2, 3, 4, 5].map((number) => (
                                      <option key={number} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {/* <span
                                  className="ant-select-arrow"
                                  unselectable="on"
                                  aria-hidden="true"
                                  style={{ userSelect: "none" }}
                                >
                                  <span
                                    role="img"
                                    aria-label="down"
                                    className="anticon anticon-down ant-select-suffix"
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="down"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                                    </svg>
                                  </span>
                                </span> */}
                              </div>
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ textAlign: "center" }}
                            >
                              <div className="gx-text-white gx-w-100 gx-pointer gx-font-weight-semi-bold gx-bg-green-0 gx-py-2 gx-px-2">
                                UPDATE
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ant-row ant-row-space-around gx-align-items-center gx-py-1">
            <div className="gx-text-capitalize gx-font-weight-bold gx-fs-xl ">
              bet Modal On/Off
            </div>
            <button
              type="button"
              role="switch"
              aria-checked={isOn}
              className={`ant-switch gx-mx-3 gx-my-1 gx-px-1 ${
                isOn ? "ant-switch-checked" : ""
              }`}
              style={{
                backgroundColor: isOn ? "green" : "red",
                transform: "scale(1.3)",
                color: "white",
              }}
              onClick={toggleSwitch}
            >
              <div className="ant-switch-handle" />
              <span className="ant-switch-inner">{isOn ? "ON" : "OFF"}</span>
            </button>
          </div>
          <div className="ant-table-wrapper gx-table-responsive">
            <div className="ant-spin-nested-loading">
              <div className="ant-spin-container">
                <div className="ant-table ant-table-small ant-table-bordered">
                  <div className="ant-table-container">
                    <div className="ant-table-content">
                      <table style={{ tableLayout: "auto" }}>
                        <colgroup />
                        <thead className="ant-table-thead">
                          <tr>
                            <th colSpan={2} className="ant-table-cell">
                              Personal Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr
                            data-row-key={1}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Client Name:</td>
                            <td className="ant-table-cell">
                              {userData?.username}
                            </td>
                          </tr>
                          <tr
                            data-row-key={2}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Client Code:</td>
                            <td className="ant-table-cell">test</td>
                          </tr>
                          <tr
                            data-row-key={3}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Chip:</td>
                            <td className="ant-table-cell">
                              {userData?.balance + userData?.liability}
                            </td>
                          </tr>
                          <tr
                            data-row-key={4}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Content No.</td>
                            <td className="ant-table-cell">0</td>
                          </tr>
                          <tr
                            data-row-key={5}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Date Of Joining</td>
                            <td className="ant-table-cell">
                              {userData?.created_at}
                            </td>
                          </tr>
                          <tr
                            data-row-key={6}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Address</td>
                            <td className="ant-table-cell">INDIA</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ant-table-wrapper gx-table-responsive">
            <div className="ant-spin-nested-loading">
              <div className="ant-spin-container">
                <div className="ant-table ant-table-small ant-table-bordered">
                  <div className="ant-table-container">
                    <div className="ant-table-content">
                      <table style={{ tableLayout: "auto" }}>
                        <colgroup />
                        <thead className="ant-table-thead">
                          <tr>
                            <th colSpan={2} className="ant-table-cell">
                              Company Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr
                            data-row-key={1}
                            className="ant-table-row ant-table-row-level-0"
                          >
                            <td className="ant-table-cell">Help Line No:</td>
                            <td className="ant-table-cell">+91 1234567890</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gx-py-4">
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-sm-18 ant-col-md-16 ant-col-lg-10 ant-col-xl-14 ant-col-xxl-14">
            <a href="/main/dashboard/">
              <div className="gx-bg-grey gx-py-2 gx-text-white gx-font-weight-semi gx-bg-flex gx-justify-content-center">
                BACK TO MAIN MENU
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="notification-container notification-container-empty">
        <div />
      </div>
    </div>
  );
}
