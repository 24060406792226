import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAPI } from "../../service/UserService";
import {
  getAnnouncementAPI,
  getCurrentBetsAPI,
} from "../../service/AuthService";
import { Accordion, Modal, Offcanvas } from "react-bootstrap";
import { useQuery } from "react-query";
import moment, { min } from "moment";
import Sidebar from "./Sidebar";

function Header() {
  const location = useLocation();
  const active = location.pathname.split("/")[1];
  const pathname = location.pathname;
  const [exposureTableModal, setExposureTableModal] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = React.useState({});
  const [show, setShow] = useState(false);
  const sideBarRef = React.useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const myMarketQuery = useQuery("myMarket", () =>
    getCurrentBetsAPI({
      betStatus: "MATCHED",
      betType: "ALL",
      pageNumber: 0,
      pageSize: 100,
      search: "",
      sports: true,
    })
  );

  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    }
  };

  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };

  const fetchProfile = () => {
    const interval = setInterval(getUserData, 3000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    getUserData();
    fetchProfile();
    getAnnouncement();
  }, []);
  const handleClickOutside = (event) => {
    if (sideBarRef && sideBarRef?.current) {
      setShow(false);
    }
  };
  useEffect(() => {
    //handle outside click

    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideBarRef]);
  if (!userData.token) {
    return (window.location.href = "/");
  }

  return (
    <>
      {show && <Sidebar show={show} setShow={setShow} />}
      <header className="ant-layout-header gx-bg-flex gx-w-100 gx-justify-content-between  gx-align-content-center ">
        <div>
          <a className=" gx-pointer" href="/main/dashboard">
            <img
              alt=""
              src="/assets/images/logo.png"
              width={130}
              height={40}
            />
          </a>
        </div>
        <div className="gx-text-white">
          <span>{userData?.username}</span>
          <br />
        </div>
        <div
          onClick={() => {
            setShow(!show);
          }}
          className=""
        >
          <ul
            className="gx-header-notifications gx-ml-auto gx-text-white"
            style={{ height: 53 }}
          >
            <li className="gx-language" />
            <div className="gx-linebar ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="auto"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 18L20 18"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  d="M4 12L20 12"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  d="M4 6L20 6"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </ul>
        </div>
      </header>
      <marquee
        class="gx-fx-xl gx-bg-grey gx-text-white gx-text-uppercase gx-font-weight-semi-bold gx-border gx-bg-flex gx-align-items-center"
        style={{
          minHeight: 28,
        }}
      >
        {announcement?.announcement}
      </marquee>
      <div class="gx-py-1 gx-bg-flex gx-justify-content-center gx-bg-white gx-box-shadow">
        {userData?.balance + userData?.liability}
        <a href="/main/pending-bets">
          | Exp: <span class="gx-text-red">{userData?.liability}</span>
        </a>
      </div>
    </>
  );
}

export default Header;
